export default {
    "安全": 'Safety',
    "放心": "Rest",
    "保障": "Assure",
    "用钱就找": "Use money to find",
    '快速审批 灵活借还': "Fast approval, flexible loan and return",
    "首页": "Home",
    "贷款分期": "Installment",
    "常见问题": "Common Problem",
    "联系我们": "Contact US",
    "我们的优势": "Our Advantage",
    "额度高": "High limit",
    "速度快": "High speed",
    "操作简单": "Easy to use",
    "低利息": "Low interest",
    "最高可借额度": "Maximum loan amount",
    "30秒审批，3分钟到账": "30 seconds for approval, 3 minutes to arrive",
    "手机即可申请，还款灵活操作": "Mobile phone can apply, repayment flexible operation",
    "0抵押0担保额度有保障": "0 mortgage 0 guarantee limit is guaranteed",
    "申请流程": "Application Process",
    "关于我们": "About US",
    "隐私政策": "Privacy Policy",
    "电话": "Phone",
    "邮箱": "Email",
    "地址": "Address",
    "官方Facebook": "Official Facebook",
    "官方Line": "Official Line",
    "立即申请": "Apply Immediately",
    "借钱来这!": "Borrow Money Come Here!",
    "最高可贷20000申请便捷": "Up to 20000 loan, easy to apply",
    "最高可贷20000快速审批": "Fast approval for a loan of up to 20,000",
    "最高可贷20000极速审批": "Fast approval for loan up to 20,000",
    "医美": "Medical Cosmetology",
    "口腔": "Oral cavity",
    "个人消费": "Personal consumption",
    "信用生活": "Credit Life",
    "问题": "Common Problem",
    "金融信贷服务": "Financial Credit Service",
    "品牌强": "Strong brand",
    "权威品牌，安全透明": "Authoritative brand, safe and transparent",
    "借款步骤": "Application steps",
    "打开App": "Open the app",
    "APP首页点击“我要申请”": `Click "I want to apply" on the homepage of the APP`,
    "获得额度": "Get quota",
    "填写信息获取额度点击“我要贷款”": `Fill in the information to obtain the quota and click "I want to loan"`,
    '实时到账': 'Real-time arrival',
    '恭喜！借款成功 到账至银行卡': "Congratulations! The loan is successfully credited to the bank card",
    "申请的门槛高吗？": "Is the threshold for application high?",
    '提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请': 'Provide your ID card and bank card. Registered users over 18 years old can apply.',
    '借款后可以多久到账？': 'How long does it take to arrive after borrowing?',
    '通过审核后最快三分钟到账': 'The account will arrive in the fastest three minutes after passing the audit and be issued to the bank card. However, due to network reasons, the arrival time may be delayed. Please notify the receiving bank card for the specific arrival time Prevail',
    '什么时候可以还款?': 'When can I repay',
    '可以进行提前还款': 'You can make early repayment. Borrow today and pay tomorrow. Repayment only calculates the interest up to the day',
    '查看更多问题': 'More questions',
    '客服': 'customer service',
    '如需联系我们请拨打客服热线': 'If you need to contact us, please call the customer service hotline',
    '时间': 'Service hours: 9:00 to 21:00 daily',

    "全球领先的贷款服务": "World-leading loan services",
    "最高可借20万": "Up to 200,000 loans available",
    "审批快": "Fast approval",
    "手机申请 自动审批": "Mobile application, automatic approval",
    "还款灵活": "Flexible repayment",
    "多种期数可选": "Multiple term options",
    "填写手机号": "Enter phone number",
    "线上申请": "Online application",
    "获取额度": "Get limit",
    "提现到账": "Withdraw to account",
    "最快1分钟到账": "Funds received in as fast as 1 minute",
    "1、申请贷款时，银行流水不够怎么办": "1. What if the bank statement is insufficient when applying for a loan?",
    "2、申请贷款失败原因主要有哪些": "2. What are the main reasons for loan application failure?",
    "通过审核后最快3分钟到账，发放到银行卡。但因网络原因，到账时间可能会延迟，具体到账时间请以是款银行卡通知为准。": "Funds can be disbursed to the bank card in as fast as 3 minutes after approval. However, due to network reasons, the actual time may be delayed. Please refer to the notification from the disbursing bank for the specific arrival time.",
    "3、借款后可以多久到账？": "3. How soon can I receive the funds after borrowing?",
    "可以进行提前还款，今天借，明天能还。还款时只计算截止到当天的利息。": "You can make early repayments, borrow today and repay tomorrow. Interest is calculated only up to the repayment date.",

    "正规": "Formal",
    "金融平台": "Financial Platform",
    "权威品牌": "Authoritative Brand",
    "30秒审批": "30 Seconds Approval",
    "高额度": "High Limit",
    "贷款产品": "Loan Products",
    "房产贷": "Real Estate Loan",
    "商品房，全款房，按揭房，经济适用房": "Commercial Housing, Full Payment Housing, Mortgage Housing, Affordable Housing",
    "汽车贷": "Car Loan",
    "抵押贷，信用贷，按揭车月供放大贷": "Mortgage Loan, Credit Loan, Expanded Monthly Payment Loan for Mortgaged Car",
    "保单贷": "Policy Loan",
    "所有寿险公司保单保单放大贷": "Policy Loan from All Life Insurance Companies",
    "经营贷": "Business Loan",
    "双周供，循环贷，存抵贷，气球贷": "Bi-weekly Supply, Revolving Loan, Deposit Loan, Balloon Loan",
    "最高获20万": "Get up to 200,000",
    "贷款帮助": "Loan Assistance",
    "找贷款麻烦？": "Having Trouble Finding a Loan?",
    "我们来帮忙": "We Can Help",
    "马上筛选": "Filter Now",
    "1分钟出额度？": "Get a Limit in 1 Minute?",
    "看看贷多少": "See How Much You Can Borrow",
    "立即评估": "Evaluate Now",
    "怎么贷最划算？": "How to Get the Best Loan?",
    "看看贷最低": "See the Lowest Rates",
    "马上测评": "Evaluate Now"
}