
import classnames from 'classnames'
import React from 'react'
import { Container } from 'react-bootstrap'
import jj1_bg from '../../resources/images/jj1_bg.png'
import jj2_bg from '../../resources/images/jj2_bg.png'
import jj3_bg from '../../resources/images/jj3_bg.png'
import jj1 from '../../resources/images/jj1.png'
import jj2 from '../../resources/images/jj2.png'
import jj3 from '../../resources/images/jj3.png'
import title_bottom_icon from '../../resources/images/title_bottom_icon.png'
import five_lc1 from '../../resources/images/five_lc1.png'
import five_lc2 from '../../resources/images/five_lc2.png'
import five_lc3 from '../../resources/images/five_lc3.png'
import five_line from '../../resources/images/five_line.png'
import './body.css'
import { useTranslation, getI18n } from 'react-i18next'
import { useContext } from 'react'
import StoreContext from '../store';
const BodyContext = () => {
    const { t } = useTranslation()
    const { language } = getI18n()
    const isTHLanguage = language == 'th'
    const isMXLanguage = language == 'mx'
    const store: any = useContext(StoreContext)
    return <>
        <Container fluid={true} className='p-t-58 px-0' id='home'>
            <div className='maxboxxsix' id='home'>
                <div className='maxbox' >
                    <div className='box_six' >
                        <div className='zg_txt_six'>
                            {t('专业广告代运营投放平台')}
                        </div>
                        <div className='bottom_txt'>{t('精准投放  免费定制方案  一站式代理')}</div>
                        <div className='bottom_txt2'>{t('助你轻松实现商业目标')}</div>
                        <div className='bt_ljty'>{t('立即体验')}</div>
                    </div>

                </div>
            </div>
            <div className='conten_box'>

                <div className='title_txt'>{t('公司简介')}</div>
                <img className='title_bottom_icon' src={title_bottom_icon} />
                <div className='content_txt'>{t('专注为国内外客户提供海外广告投放服务，在Facebook、Google、Tiktok以及Apple Search等海外广告媒体渠道及其附属的相应互联网络平台投放广告。')}</div>
                <div className='content_txt'>{t('提供互联网游戏开发、经营业务系统开发等互联网技术软件开发服务及信息技术咨询服务。')}</div>
                <div className='neirong_box'>
                    <div className='list_box'>
                        <img className='jj1_bg' src={jj1_bg} />
                    </div>
                    <div className='list_box'>
                        <img className='jj1_bg' src={jj1_bg} />
                    </div>
                    <div className='list_box'>
                        <img className='jj1_bg' src={jj1_bg} />
                    </div>
                </div>
            </div>


        </Container>
    </>
}
export default BodyContext