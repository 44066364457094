
import classnames from 'classnames'
import React from 'react'
import { Container } from 'react-bootstrap'
import four_bg from '../../resources/images/four_bg.png'
import img from '../../resources/images/img.png'
import img2 from '../../resources/images/img2.png'
import img3 from '../../resources/images/img3.png'
import img_bg from '../../resources/images/img_bg.png'
import img_1 from '../../resources/images/img_1.png'
import img_2 from '../../resources/images/img_2.png'
import img_3 from '../../resources/images/img_3.png'
import step3 from '../../resources/images/mx-step3.png'
import pb1 from '../../resources/images/problem1.png'
import pb2 from '../../resources/images/problem2.png'
import pb3 from '../../resources/images/problem3.png'
import './body.css'
import { useTranslation, getI18n } from 'react-i18next'
import { useContext } from 'react'
import StoreContext from '../store';
const BodyContext = () => {
    const { t } = useTranslation()
    const { language } = getI18n()
    const isTHLanguage = language == 'th'
    const isMXLanguage = language == 'mx'
    const store: any = useContext(StoreContext)
    return <>
        <Container fluid={true} className='p-t-58 px-0' id='home'>
            <div className='maxbox' >
                <div className='box' >
                    <div>
                        <div className='title_txt'>Hey! I’m <span className='zd_Txt'>Ololade</span></div>
                        <div className='title_txt2'>{t('全球领先的贷款服务')}</div>
                        <div className='title_txt3'>I make websites that converts more</div>
                        <div className='title_txt_bt'>{t('立即申请')}</div>
                    </div>
                    <div className='banner_box'>
                        <img className='banner' src={four_bg} />
                    </div>
                </div>
                <div className='content_box' id='service'>
                    <div className='text_title' >{t('我们的优势')}</div>
                    <div className='box_list' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div className='box'>
                            <img className='box_img' src={img} />
                            <div className='top_Txt'>{t('额度高')}</div>
                            <div className='bottom_Txt'>{t('最高可借20万')}</div>
                        </div>
                        <div className='box'>
                            <img className='box_img' src={img2} />
                            <div className='top_Txt'>{t('审批快')}</div>
                            <div className='bottom_Txt'>{t('手机申请 自动审批')} </div>
                        </div>
                        <div className='box'>
                            <img className='box_img' src={img3} />
                            <div className='top_Txt'>{t('还款灵活')}</div>
                            <div className='bottom_Txt'>{t('多种期数可选')}</div>
                        </div>
                    </div>
                    <div className='text_title2' id='step'>{t('申请流程')}</div>
                    <div className='box_list2'>
                        <div className='box2'>
                            <img className='box_img' src={img_bg} />
                            <img className='box_img2' src={img_1} />
                            <div className='top_Txt'>{t('填写手机号')}</div>
                            <div className='bottom_Txt'>{t('线上申请')}</div>
                        </div>
                        <div className='line'></div>
                        <div className='box3'>
                            <img className='box_img' src={img_bg} />
                            <img className='box_img2' src={img_2} />
                            <div className='top_Txt'>{t('获取额度')}</div>
                            <div className='bottom_Txt'>{t('线上申请')}</div>
                        </div>
                        <div className='line'></div>
                        <div className='box4'>
                            <img className='box_img' src={img_bg} />
                            <img className='box_img2' src={img_3} />
                            <div className='top_Txt'>{t('提现到账')}</div>
                            <div className='bottom_Txt'>{t('最快1分钟到账')}</div>
                        </div>

                    </div>
                    <div id='question'>
                        <div className='text_title2' >{t('常见问题')}</div>
                        <div className='box_list3'>
                            <div className='text_top'>{t('1、申请贷款时，银行流水不够怎么办')}</div>
                            <div className='text_bottom'>{t('提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请')}...</div>
                        </div>
                        <div className='box_list3'>
                            <div className='text_top'>{t('2、申请贷款失败原因主要有哪些')}</div>
                            <div className='text_bottom'>{t('通过审核后最快3分钟到账，发放到银行卡。但因网络原因，到账时间可能会延迟，具体到账时间请以是款银行卡通知为准。')}</div>
                        </div>
                        <div className='box_list3'>
                            <div className='text_top'>{t('3、借款后可以多久到账？')}</div>
                            <div className='text_bottom'>{t('可以进行提前还款，今天借，明天能还。还款时只计算截止到当天的利息。')}</div>
                        </div>
                    </div>

                </div>

            </div>


        </Container>
    </>
}
export default BodyContext