export default {
    '首页': 'Página de inicio',
    '贷款分期': 'Plazas de péstamos ',
    '常见问题': 'Preguntas comunes',
    '联系我们': 'Contacto',
    '金融信贷服务': 'Servicios financieros de crédito',
    '用钱就找': 'Si necesitas dinero ',
    '速度快': "Velocidad rápida",
    '额度高': "Cuotas elevadas",
    '操作简单': "Operación sencilla",
    '品牌强': "Marcas excellentes",
    '最高可借额度': 'Cuetos de préstamos máximos',
    '30秒审批，3分钟到账': 'identicifación en 30 segundos y 3 minutos para la cuenta',
    '手机即可申请，还款灵活操作': 'Aplicación móvil y reembolso flexible',
    '权威品牌，安全透明': 'Marca autorizada, segura ',
    '借款步骤': 'Pasos del préstamo',
    '打开App': 'abierto la aplicación',
    '获得额度': 'obtener los cuetos',
    '实时到账': 'llegada al tiempo',
    'APP首页点击“我要申请”': 'Haga clic en "Quiero solicitar" en la página de inicio de la APP',
    '填写信息获取额度点击“我要贷款”': 'Rellene sus datos para obtener una línea de crédito y haga clic en "Quiero un préstamo"',
    '恭喜！借款成功 到账至银行卡': 'Enhorabuena. El préstamo ha sido un éxito',
    '申请的门槛高吗？': '¿ Umbral de aplicación es alto?',
    '提供本人身份证，本人银行卡。年龄在18岁以上的注册用户，均可申请': 'Proporcione el tuyo propio  carné de identidad y su tarjeta bancaria. Los usuarios registrados mayores de 18 años pueden solicitarlo',
    '借款后可以多久到账？': '¿Cuánto tiempo puedo pedir prestado y cuánto tarda en llegar a una cuenta?',
    '通过审核后最快三分钟到账': 'La hora de pago más temprana es tres minutos después de la verificación y la emisión a la tarjeta bancaria, pero debido a razones de la red, la hora de llegada puede retrasarse, por favor consulte la notificación de la tarjeta bancaria receptora para la hora de llegada específica.',
    '什么时候可以还款?': '¿Cuándo puedes devolver el préstamo?',
    '可以进行提前还款': 'El reembolso anticipado es posible, puede pedir un préstamo hoy y reembolsarlo mañana, y el reembolso sólo se calculará con los intereses hasta esa fecha',
    '时间': '(Horario de servicio: de 9:00 a 21:00 horas todos los días)',
    '如需联系我们请拨打客服热线': 'Si necesita ponerse en contacto con nosotros, llame a nuestra línea de atención al cliente',
    '客服': 'Servicio de atención al cliente',
    '下载App': 'Descargue la aplicación',
    '官方Facebook': 'Facebook oficial',
    'Ios客户端': 'IOS',
    '安卓客户端': 'Android',
    '推荐与服务': 'Recomendaciones y servicios',
    '服务号': 'Número de servicio',
    '官方邮箱': 'Correo electrónico oficial',
    '地址': 'Dirección',
    '关于我们': 'Sobre nosotros',
    '官方Line': 'Línea oficial',
    '查看更多问题': 'Ves más preguntas',
    "立即申请": "Solicite ahora",
    "信用生活": "Vida crediticia",
    "个人消费": "Consumo personal",
    "借钱来这!": "¡Pide dinero prestado aquí!",
    "安全": "Seguridad",
    "放心": "Tranquilidad",
    "保障": "Protección",
    "快速审批 灵活借还": " Aprobación rápida Préstamo y reembolso flexibles",
    "我们的优势": "Nuestros puntos fuertes",
    "低利息": "Interés bajo",
    "0抵押0担保额度有保障": "0 garantías y 0 avales con línea de crédito garantizada",
    "申请流程": "Proceso de solicitud",
    "官方": "Autoridades",
    "隐私政策": 'Política de privacidad',
    "电话": "Teléfono",
    "邮箱": "Correo electrónico ",
    "最高可贷20000申请便捷": "Facilidad para solicitar préstamos de hasta 20.000",
    "最高可贷20000快速审批": "Aprobación rápida de hasta 20.000 préstamos",
    "最高可贷20000极速审批": "Hasta 20.000 préstamos para una aprobación extremadamente rápida",
    "口腔": 'Cavidad bucal',
    "医美": "Belleza médica",
    "全球领先的贷款服务": "Servicio de préstamos líder en el mundo",
    "最高可借20万": "Préstamo máximo de 200,000",
    "审批快": "Aprobación rápida",
    "手机申请 自动审批": "Solicitud móvil Aprobación automática",
    "还款灵活": "Reembolso flexible",
    "多种期数可选": "Varias opciones de plazo",
    "填写手机号": "Ingrese el número de teléfono",
    "线上申请": "Solicitud en línea",
    "获取额度": "Obtener límite",
    "提现到账": "Retiro a la cuenta",
    "最快1分钟到账": "Disponible en 1 minuto",
    "1、申请贷款时，银行流水不够怎么办": "1. ¿Qué hacer si no hay suficientes extractos bancarios al solicitar un préstamo?",
    "2、申请贷款失败原因主要有哪些": "2. ¿Cuáles son las principales razones para la denegación de préstamos?",
    "通过审核后最快3分钟到账，发放到银行卡。但因网络原因，到账时间可能会延迟，具体到账时间请以是款银行卡通知为准。": "Después de la aprobación, los fondos pueden estar disponibles en 3 minutos y se depositarán en la tarjeta bancaria. Sin embargo, debido a problemas de red, el tiempo de procesamiento puede retrasarse. Consulte las notificaciones de su tarjeta bancaria para obtener el tiempo exacto de depósito.",
    "3、借款后可以多久到账？": "3. ¿Cuánto tiempo tarda en llegar el dinero después de solicitar el préstamo?",
    "可以进行提前还款，今天借，明天能还。还款时只计算截止到当天的利息。": "Puede hacer un reembolso anticipado, pedir prestado hoy y devolverlo mañana. Los intereses solo se calculan hasta el día del reembolso.",
    "正规": "Regular",
    "金融平台": "Plataforma financiera",
    "权威品牌": "Marca autoritaria",
    "30秒审批": "Aprobación en 30 segundos",
    "高额度": "Límite alto",
    "贷款产品": "Productos de préstamo",
    "房产贷": "Préstamo inmobiliario",
    "商品房，全款房，按揭房，经济适用房": "Viviendas comerciales, viviendas pagadas en su totalidad, viviendas hipotecadas, viviendas económicas",
    "汽车贷": "Préstamo de coche",
    "抵押贷，信用贷，按揭车月供放大贷": "Préstamo con garantía, préstamo de crédito, préstamo ampliado con mensualidades de coche hipotecado",
    "保单贷": "Préstamo de póliza de seguro",
    "所有寿险公司保单保单放大贷": "Préstamo ampliado de póliza de seguro de todas las compañías de seguros de vida",
    "经营贷": "Préstamo comercial",
    "双周供，循环贷，存抵贷，气球贷": "Pago quincenal, préstamo rotativo, préstamo compensatorio, préstamo balón",
    "最高获20万": "Obtén hasta 200,000",
    "贷款帮助": "Ayuda con préstamos",
    "找贷款麻烦？": "¿Tienes problemas para encontrar un préstamo?",
    "我们来帮忙": "Déjanos ayudarte",
    "马上筛选": "Filtrar ahora",
    "1分钟出额度？": "¿Obtén un límite en 1 minuto?",
    "看看贷多少": "Ve cuánto puedes pedir prestado",
    "立即评估": "Evaluar ahora",
    "怎么贷最划算？": "¿Cuál es la mejor forma de obtener un préstamo?",
    "看看贷最低": "Ve el préstamo con la tasa más baja",
    "马上测评": "Evaluar ahora"
}