
import classnames from 'classnames'
import React from 'react'
import { Container } from 'react-bootstrap'
import icon1 from '../../resources/images/icon1.png'
import icon2 from '../../resources/images/icon2.png'
import icon3 from '../../resources/images/icon3.png'
import five_icon21 from '../../resources/images/five_icon21.png'
import five_icon22 from '../../resources/images/five_icon22.png'
import five_icon23 from '../../resources/images/five_icon23.png'
import five_icon24 from '../../resources/images/five_icon24.png'
import five_lc1 from '../../resources/images/five_lc1.png'
import five_lc2 from '../../resources/images/five_lc2.png'
import five_lc3 from '../../resources/images/five_lc3.png'
import five_line from '../../resources/images/five_line.png'
import './body.css'
import { useTranslation, getI18n } from 'react-i18next'
import { useContext } from 'react'
import StoreContext from '../store';
const BodyContext = () => {
    const { t } = useTranslation()
    const { language } = getI18n()
    const isTHLanguage = language == 'th'
    const isMXLanguage = language == 'mx'
    const store: any = useContext(StoreContext)
    return <>
        <Container fluid={true} className='p-t-58 px-0' id='home'>
            <div className='maxboxx' id='home'>
                <div className='maxbox' >
                    <div className='box_five' >
                        <div className='zg_txt_five' style={{ fontSize: "90px" }}>
                            <span className='zgpttxt'>{t('正规')}</span>{t('金融平台')}
                        </div>
                        <div className='bottom_txt'>{t('全球领先的贷款服务')}</div>

                    </div>

                </div>
            </div>
            <div className='content_box'>
                <div className='content_1' id='service'>
                    <div className='content_title'><span className='szm_txt'>O</span>UR <span className='szm_txt'>A</span>DVANTAGE</div>
                    <div className="text-with-lines">
                        <span>{t('我们的优势')}</span>
                    </div>
                    <div className='content_nr'>
                        <div className='content_box1'>
                            <img className='list_icon' src={icon1} />
                            <div className='bottom_txt'>
                                <div className='ppq'>{t('品牌强')}</div>
                                <div className='ppq_bottom'>{t('权威品牌')}</div>
                            </div>

                        </div>
                        <div className='content_box1'>
                            <img className='list_icon' src={icon2} />
                            <div className='bottom_txt'>
                                <div className='ppq'>{t('速度快')}</div>
                                <div className='ppq_bottom'>{t('30秒审批')}</div>
                            </div>

                        </div>
                        <div className='content_box1'>
                            <img className='list_icon' src={icon3} />
                            <div className='bottom_txt'>
                                <div className='ppq'>{t('高额度')}</div>
                                <div className='ppq_bottom'>{t('最高可借20万')}</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='content_2' id='step'>
                    <div className='content_title'><span className='szm_txt'>L</span>OAN <span className='szm_txt'>P</span>RODUCTS</div>
                    <div className="text-with-lines">
                        <span>{t('贷款产品')}</span>
                    </div>
                    <div className='content_nr'>
                        <div className='content_box1'>
                            <div className='title_txt'>{t('房产贷')}</div>
                            <img className='list_icon' src={five_icon21} />
                            <div className='bottom_txt'>
                                <div className='ppq_bottom'>{t('商品房，全款房，按揭房，经济适用房')}</div>
                            </div>

                        </div>
                        <div className='content_box1'>
                            <div className='title_txt'>{t('汽车贷')}</div>
                            <img className='list_icon' src={five_icon22} />
                            <div className='bottom_txt'>
                                <div className='ppq_bottom'>{t('抵押贷，信用贷，按揭车月供放大贷')}</div>
                            </div>
                        </div>
                        <div className='content_box1'>
                            <div className='title_txt'>{t('保单贷')}</div>
                            <img className='list_icon' src={five_icon23} />
                            <div className='bottom_txt'>
                                <div className='ppq_bottom'>{t('所有寿险公司保单保单放大贷')}</div>
                            </div>
                        </div>
                        <div className='content_box1'>
                            <div className='title_txt'>{t('经营贷')}</div>
                            <img className='list_icon' src={five_icon24} />
                            <div className='bottom_txt'>
                                <div className='ppq_bottom'>{t('双周供，循环贷，存抵贷，气球贷')}</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='content_3'>
                    <div className='content_title'><span className='szm_txt'>A</span>PPLICATION <span className='szm_txt'>P</span>ROCESS</div>
                    <div className="text-with-lines">
                        <span>{t('申请流程')}</span>
                    </div>
                    <div className='content_nr'>
                        <div className='content_box1'>
                            <img className='list_icon' src={five_lc1} />
                            <div className='bottom_txt'>
                                <div className='title_txt2'>{t('填写手机号')}</div>
                                <div className='ppq_bottom2'>{t('线上申请')}</div>
                            </div>

                        </div>
                        <img className='list_icon' src={five_line} />

                        <div className='content_box1'>
                            <img className='list_icon2' src={five_lc2} />
                            <div className='bottom_txt'>
                                <div className='title_txt2'>{t('获取额度')}</div>
                                <div className='ppq_bottom2'>{t('最高获20万')}</div>
                            </div>

                        </div>
                        <img className='list_icon' src={five_line} />

                        <div className='content_box1'>
                            <img className='list_icon3' src={five_lc3} />
                            <div className='bottom_txt'>
                                <div className='title_txt2'>{t('提现到账')}</div>
                                <div className='ppq_bottom2'>{t('最快1分钟到账')}</div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div className='content_4' id='question'>
                <div className='content_title'><span className='szm_txt'>L</span>OAN <span className='szm_txt'>H</span>ELP</div>
                <div className="text-with-lines2">
                    <span>{t('贷款帮助')}</span>
                </div>
                <div className='content_nr'>
                    <div className='content_box1'>
                        <div className='bottom_txt'>
                            <div className='title_txt3'>{t('找贷款麻烦？')}</div>
                            <div className='ppq_bottom3'>{t('我们来帮忙')}</div>
                            <div className='bt_an'>{t('马上筛选')}</div>
                        </div>

                    </div>
                    <div className='content_box1'>
                        <div className='bottom_txt'>
                            <div className='title_txt3'>{t('1分钟出额度？')}</div>
                            <div className='ppq_bottom3'>{t('看看贷多少')}</div>
                            <div className='bt_an'>{t('立即评估')} </div>
                        </div>

                    </div>
                    <div className='content_box1'>
                        <div className='bottom_txt'>
                            <div className='title_txt3'>{t('怎么贷最划算？')}</div>
                            <div className='ppq_bottom3'>{t('看看贷最低')}</div>
                            <div className='bt_an'>{t('马上测评')}</div>
                        </div>

                    </div>
                </div>
            </div>


        </Container>
    </>
}
export default BodyContext